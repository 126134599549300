import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import React, { useContext, useState } from 'react';

import { FooterRcLinks } from '@/components/footer/footer';
import { RcLink } from '@/components/rc-link';
import { MenuItemType } from '@/config-schema/navigation';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import Drawer, {
  DrawerBody,
  DrawerHeader,
} from '@/design-system-components/drawer/drawer';
import { useHeaderLinks } from '@/hooks/config/use-header-links';
import { usePathname } from '@/i18n/navigation';
import { RedirectModalContext } from '@/root-provider/redirect-modal-provider';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';
import { Icon } from '../icon';
import { maxItemsForSingleLayer } from './constants';
import { NavigationMenuItem } from './navigation-menu-item';
import { customNavigation } from './utils';

export const MobileHeader = (
  props: RewardsCentralConfiguration['application']['components']['header'] & {
    features: RewardsCentralConfiguration['application']['features'];
    navigationConfiguration: RewardsCentralConfiguration['application']['components']['navigation'];
  },
) => {
  const { redeemHeaderLinks, earnHeaderLinks, headerLinks } = useHeaderLinks(
    props.features,
    props.navigationConfiguration,
  );
  const [isOpen, setIsOpen] = useState(false);
  const pathName = usePathname();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button
        onClick={toggleDrawer}
        aria-expanded={isOpen}
        aria-label={isOpen ? 'Close Menu' : 'Open Menu'}
        data-testid={isOpen ? 'close-menu' : 'open-menu'}
      >
        <Icon
          name="bars"
          fill={props.hamburgerIconColor ?? props.color}
          className="h-6 w-6"
        />
      </button>
      <Drawer variant="left" isOpen={isOpen} onOpenChange={setIsOpen}>
        <DrawerHeader className="flex-row bg-[var(--rc-header--bg-color)] py-6 ">
          <button
            onClick={toggleDrawer}
            className="flex"
            aria-label="Close Menu"
          >
            <Icon name="xmark" className="h-6 w-6 fill-[--rc-header--color]" />
          </button>
        </DrawerHeader>
        <DrawerBody className="bg-[var(--rc-header--bg-color)] px-0 ">
          <NavigationMenu.Root
            orientation="vertical"
            className="h-full [&>div]:h-full "
          >
            <NavigationMenu.List className="flex h-full flex-col gap-3 pt-6 [&>li:last-child]:pb-6">
              {headerLinks.length > maxItemsForSingleLayer ? (
                <MobileTwoLayersMenu
                  earnMenuGroups={earnHeaderLinks}
                  redeemMenuGroups={redeemHeaderLinks}
                  onCloseNavigation={toggleDrawer}
                />
              ) : (
                <MobileOneLayerMenu
                  headerLinks={headerLinks}
                  onCloseNavigation={toggleDrawer}
                />
              )}

              {FooterRcLinks.map((d, index) => (
                <NavigationMenu.Item
                  key={d.href}
                  className={cn('mx-4 py-2', [
                    index === 0
                      ? 'mt-auto border-t border-neutral-400 pt-6'
                      : '',
                  ])}
                >
                  <RcLink
                    key={d.href}
                    href={d.href}
                    className="mb-4 text-[var(--rc-menu-item--color)]"
                    onClick={(e) => onClickLink(d.href, e)}
                  >
                    <p>{d.translation}</p>
                  </RcLink>
                </NavigationMenu.Item>
              ))}
            </NavigationMenu.List>
          </NavigationMenu.Root>
        </DrawerBody>
      </Drawer>
    </>
  );

  function onClickLink(
    link: string,
    event: React.MouseEvent<HTMLAnchorElement>,
  ) {
    if (pathName === link) {
      event.preventDefault();
      return;
    }

    setIsOpen(!open);
  }
};
MobileHeader.displayName = 'MobileHeader';

function MobileOneLayerMenu(props: {
  headerLinks: MenuItemType[];
  onCloseNavigation?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}) {
  const { headerLinks, onCloseNavigation } = props;
  return (
    <>
      {headerLinks.map((menuItem) => (
        <NavigationMenuItem
          href={menuItem.href}
          key={menuItem.itemType}
          onClick={onCloseNavigation}
          menuItem={menuItem}
          displayMode="simple-with-icon"
          className="flex-row px-6 py-2"
        />
      ))}
    </>
  );
}
MobileOneLayerMenu.displayName = 'MobileOneLayerMenu';

function MobileTwoLayersMenu(props: {
  earnMenuGroups: MenuItemType[];
  redeemMenuGroups: MenuItemType[];
  onCloseNavigation?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}) {
  const { setShowRedirectModal } = useContext(RedirectModalContext);
  const subMenuContentStyle = cn(
    'mb-4 py-4 pl-8 pr-4',
    'bg-[var(--rc-menu-item--hover-bg-color)]',
  );
  const triggerCaretStyle = cn(
    'duration-300 group-data-[state=open]:rotate-180',
  );
  const triggerStyle = cn(
    'group',
    'text-[var(--rc-menu-item--color)]',
    'flex w-full items-center justify-between',
    'px-4 pb-5 pt-1',
  );

  const { t } = useTranslation();
  const { earnMenuGroups, redeemMenuGroups, onCloseNavigation } = props;

  return (
    <>
      {redeemMenuGroups.length > 0 && (
        // TODO: Create a Mobile Main Navigation Item in design-system-components
        <NavigationMenu.Item>
          <NavigationMenu.Trigger className={triggerStyle}>
            {t('Redeem Rewards')}
            <span aria-hidden className={triggerCaretStyle}>
              <Icon
                name="chevron-down"
                className={cn('h-4 w-4', 'fill-[var(--rc-menu-item--color)]')}
              />
            </span>
          </NavigationMenu.Trigger>
          <NavigationMenu.Content
            onInteractOutside={(event) => event.preventDefault()}
            onPointerDownOutside={(event) => event.preventDefault()}
            className={subMenuContentStyle}
          >
            <NavigationMenu.Sub>
              <ul className="grid gap-4">
                {redeemMenuGroups.map((menuItem) => (
                  <NavigationMenuItem
                    href={menuItem.href}
                    key={menuItem.itemType}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                      //MouseEventHandler
                      customNavigation(menuItem, event, setShowRedirectModal);
                      onCloseNavigation && onCloseNavigation(event);
                    }}
                    menuItem={menuItem}
                    displayMode="full"
                    className="px-2 py-2"
                  />
                ))}
              </ul>
            </NavigationMenu.Sub>
          </NavigationMenu.Content>
        </NavigationMenu.Item>
      )}

      {earnMenuGroups.length > 0 && (
        <NavigationMenu.Item>
          <NavigationMenu.Trigger className={triggerStyle}>
            {t('Earn More')}
            <span aria-hidden className={triggerCaretStyle}>
              <Icon
                name="chevron-down"
                className={cn('h-4 w-4', 'fill-[var(--rc-menu-item--color)]')}
              />
            </span>
          </NavigationMenu.Trigger>
          <NavigationMenu.Content
            onInteractOutside={(event) => event.preventDefault()}
            onPointerDownOutside={(event) => event.preventDefault()}
            className={subMenuContentStyle}
          >
            <NavigationMenu.Sub>
              <ul>
                {earnMenuGroups.map((menuItem) => (
                  <NavigationMenuItem
                    href={menuItem.href}
                    key={menuItem.itemType}
                    onClick={onCloseNavigation}
                    menuItem={menuItem}
                    displayMode="full"
                    className="px-2 py-2"
                  />
                ))}
              </ul>
            </NavigationMenu.Sub>
          </NavigationMenu.Content>
        </NavigationMenu.Item>
      )}
    </>
  );
}
MobileTwoLayersMenu.displayName = 'MobileTwoLayersMenu';
